<template>
    <section id="thank-you" class="app bg2 wf-section">
        <div class="w-layout-blockcontainer container-8 w-container"><img src="/images/choose.svg" loading="lazy" alt="" class="image-5">
        <div class="text-block-6 yellow">New series drops on the first of each month</div>
        <ul role="list" class="list w-list-unstyled">
        <li v-for="(series, index) in releasedSeries" :key="index" class="series-card">
            <a @click="nav_selected(series.key)" class="series-card-container w-inline-block">
            <h2 class="heading-6">SERIES {{ series.name }}</h2>
            <img :src="series.image" loading="lazy" alt="">
            <div class="div-block-19">
                <div class="text-block-7">{{ getOwned(series.key) }} Owned</div>
                <div class="text-block-7">{{ getSealed(series.key) }} Sealed</div>
            </div>
            </a>
        </li>
        </ul>
        </div>
    </section>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { useRevealStore } from '../store/reveal'
  import { SeriesData } from '@/assets/data/series.js'
  export default {
    name: 'SeriesSelect',
    setup(props, {emit}){
      const series_data = ref(SeriesData)
      const releasedSeries = computed(() => {
        return Object.keys(series_data.value)
        .filter(key => series_data.value[key].released)
        .map(key => ({name: key, ...series_data.value[key]}))
      })

      const nav_selected = (series_key) => {
        const series = series_data.value[series_key]
        if(getSealed(series_key) === 0){
          window.open(series.redirect_url, '_blank')
        } else {
          emit('next', 'ConfirmationView')
        }
      }

      const revealStore = useRevealStore()

      const getOwned = (series_key)  => {
        console.debug(series_key)
        const owned = revealStore.getTotalRevealedTokens.length + revealStore.getTotalSealedTokens.length
        return owned
      }
      
      const getSealed = (series_key) => {
        console.debug(series_key)
        const sealed = revealStore.getTotalSealedTokens.length
        return sealed
      }
  
      return {
        series_data,
        getOwned,
        getSealed,
        releasedSeries,
        nav_selected
      }
    }
  }
  </script>
  
    <style>
    .counter{
      display: flex;
      flex-direction: column;
      align-items: center;
  
    }
    /* @media (max-width: 768px) {
    .counter {
      margin-top: 1rem;
    }
  } */
    .controls{
      font-size: 2rem;
      background: none;
      border: none;
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
      .controls:hover{
      background: #ccff0010;
      cursor: pointer;
      color: #cf0;
    }
    .price-symbol{
      color: #cf0
    }
    #demoInput{
      font-size: 4rem;
      text-align: center;
      padding: 0;
      border: none;
      margin-bottom: 1rem;
      background: none;
    }
    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .button-2.w-button:disabled {
      background-color: #606060 !important;
      color: #0f0f0f !important;
    }
  </style>
  