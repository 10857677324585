<template>
  <div>
    <NavBar></NavBar>
    <section id="thank-you" class="app bg4 wf-section">
    <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/purchase_complete.svg" loading="lazy" width="851.5" alt="" class="loader">
      <a href="https://discord.gg/zergstudio" target="_blank" class="signin w-inline-block">
        <div class="sign-in-button">ESCAPE TO THE HIDEOUT</div>
      </a>
      <a v-if="user_name" :href="'https://awrd.gg/' + user_name" target="_blank" class="link-2 black">View AWRD Profile</a>
      <!-- <a href="https://awrd.gg/6154" target="_blank" class="anointed w-inline-block">
        <div class="claimbadge"><img src="@/assets/images/anointed.png" loading="lazy" width="50" height="50" srcset="@/assets/images/anointed.png 500w, @/assets/images/anointed.png 512w" sizes="50px" alt="">
          <div class="text-block-4">Collect Anointed Talisman</div>
        </div>
      </a> -->
    </div>
  </section>
  </div>
  </template>
    
    <script>
    import NavBar from '../components/NavBar.vue'
    import { useEligibilityStore } from '../store/eligibility'
    import { useStripeStore } from '../store/stripe'
    import { onMounted, computed, ref } from 'vue'
    import Cookies from 'js-cookie'
    import { useRouter } from 'vue-router'
    export default {
      name: 'PaymentSucceeded',
      components: {
        NavBar
      },
      setup() {
        const router = useRouter()
        const eligibiltyStore = useEligibilityStore()
        const stripeStore = useStripeStore()
        const eligibility = computed(() => eligibiltyStore.getEligibility)
        const user_name = ref(null)
        console.debug(user_name)
  
        const skipToMint = () => {
          const eligibilityStore = useEligibilityStore()
          eligibilityStore.no_talisman = true
          router.push('/')
        }
        const startOver= () => {
          router.push('/')
        }
        onMounted(async () => {
          if (eligibility.value === 'wow') {
            let warriorValue = Cookies.get('warrior') || 'none'
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'warrior_ref',
              'warriorValue': warriorValue
            })

            Cookies.remove('warrior')
          }

          const session_id = router.currentRoute.value.query.session_id
          const subscription_id = router.currentRoute.value.query.subscription_id
          user_name.value = router.currentRoute.value.query.username

          console.debug("URL DATA", session_id, subscription_id)

          if (session_id || subscription_id) {
            try {
              await stripeStore.callCheckout(session_id, subscription_id)
            } catch (error) {
              console.debug('Error calling Zerg API', error)
            }
          }
        })
  
        return{
          eligibility,
          skipToMint,
          startOver,
          user_name
        }
      }
    }
    </script>
    <style scoped>
    .anointed {
      margin-top: 2rem;
      position: absolute;
      bottom: 20%;
    }
    .signin{
      background-color: #0f0f0f;
    }
    .sign-in-button{
      color: #cf0;
    }
    </style>