<template>
  <section id="mint" class="app talisman-app wf-section bgMint">
    <div class="w-layout-blockcontainer main main-list w-container">
      <div class="mint-controller">
        <img src="@/assets/images/placeholder.gif" loading="lazy" width="500" height="500" srcset="@/assets/images/placeholder.gif 500w, @/assets/images/placeholder.gif 800w, @/assets/images/placeholder.gif 1024w" sizes="(max-width: 767px) 97vw, 500px" alt="" style="width:100%;height:auto;max-width:500px;">
        <div class="html-embed w-embed w-script">
          <div class="counter">
            <input v-model.number="value" id="demoInput" type="number" min="0" max="100" placeholder="0">
            <div class="buttons">
              <button class="controls" @click="decrement">-</button>
              <button class="controls" @click="increment">+</button>
            </div>
          </div>
        </div>
      </div>
      <div class="springboard">
        <div class="div-block-4">
          <p class="paragraph-3 price">{{ price }}<strong class="price-symbol">Ξ</strong></p>
        </div>
        <button id="mint" @click="selectMint"  v-bind:disabled="value <= 0" class="button-2 w-button"><strong>MINT NOW</strong></button>
        <div v-if="selectedFreeCount || selectedHalfPricedCount" class="div-block-4">
          <p @click="nav_back" class="paragraph-3">{{ remainingFreeCount || '--' }} Free</p>
          <p @click="nav_back" class="paragraph-3">{{ remainingHalfPricedCount || '--' }} Half-priced</p>
        </div>
        <div v-if="errorMsg" class="text-block-3">{{ errorMsg }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useEligibilityStore } from '../store/eligibility';
import { useContractStore } from '../store/contract'
export default {
  name: 'SelectionView',
  setup(props, {emit}){
    const eligibilityStore = useEligibilityStore()
    const errorMsg = ref('')

    const nav_back = async () => {
      emit('next', 'ConfirmationView')
    }

    const getSelectedCount = (selectedArray) => {
      return selectedArray.reduce((total, item) => total + item.selected, 0)
    }

    const value = ref(getSelectedCount(eligibilityStore.getSelectedFree) + getSelectedCount(eligibilityStore.getSelectedHalf))

    const price = computed(() => {
      let basePrice = 0.0
      let mintCount = value.value
      let freeCount = getSelectedCount(eligibilityStore.getSelectedFree)
      let halfPricedCount = getSelectedCount(eligibilityStore.getSelectedHalf)
      let calculatedPrice = 0

      if (mintCount <= freeCount) {
        calculatedPrice = basePrice
      } else if (mintCount <= freeCount + halfPricedCount) {
        calculatedPrice = basePrice + ((mintCount - freeCount) * 0.05)
      } else {
        calculatedPrice = basePrice + ((halfPricedCount) * 0.05) + ((mintCount - freeCount - halfPricedCount) * 0.1)
      }

      return Number(calculatedPrice.toFixed(2))
    });

    const selectedFreeCount = ref(eligibilityStore.getSelectedFree)
    const selectedHalfPricedCount = ref(eligibilityStore.getSelectedHalf)

    const totalSelectedMints = computed(() => value.value);

    const remainingFreeCount = computed(() => {
      let totalFree = getSelectedCount(selectedFreeCount.value);
      return totalFree - totalSelectedMints.value >= 0 ? totalFree - totalSelectedMints.value : 0;
    });

    const remainingHalfPricedCount = computed(() => {
      let totalFree = getSelectedCount(selectedFreeCount.value);
      let totalHalf = getSelectedCount(selectedHalfPricedCount.value);
      if (totalSelectedMints.value > totalFree) {
        return totalHalf - (totalSelectedMints.value - totalFree) >= 0 ? totalHalf - (totalSelectedMints.value - totalFree) : 0;
      } else {
        return totalHalf;
      }
    });

    const initialFreeCountWasNonZero = ref(eligibilityStore.getSelectedFree > 0)
    const initialHalfPricedCountWasNonZero = ref(eligibilityStore.getSelectedHalfMint > 0)
    watch(value, () => {
      remainingFreeCount.value = Math.max(eligibilityStore.freeCountGetter - value.value, 0);
      remainingHalfPricedCount.value = Math.max(eligibilityStore.halfPricedCountGetter - Math.max(value.value - eligibilityStore.freeCountGetter, 0), 0);
    });

    const selectMint = async () => {
      if(value.value < 1 || value.value > 100) {
        errorMsg.value = 'Value should be between 0 and 100'
      } else {
        errorMsg.value = ''
        const contractStore = useContractStore()

        let mintCount = value.value
        // let freeCount = eligibilityStore.freeCountGetter
        // let halfPricedCount = eligibilityStore.halfPricedCountGetter
        
        let freeCount = getSelectedCount(selectedFreeCount.value);
        let halfPricedCount = getSelectedCount(selectedHalfPricedCount.value);
        let freeMints = Math.min(mintCount, freeCount)
        let halfPricedMints = mintCount > freeCount ? Math.min(mintCount - freeCount, halfPricedCount) : 0
        let alMints = mintCount > freeCount + halfPricedCount ? mintCount - freeCount - halfPricedCount : 0

        let nMints = [freeMints, halfPricedMints, alMints] 
        let result = await contractStore.multiClaim(nMints, price.value)
        if(result){
          emit('terminate', true)
        } else {
          if('insufficient funds for intrinsic transaction' in contractStore.mintError){
                errorMsg.value = "Insufficient funds in connected wallet to mint Warriors."
            } else {
                errorMsg.value = "There was an error processing your mint, please insure your wallet is connected and you have sufficient funds to cover the mint cost."//contractStore.mintError 
            }
          setTimeout(() => {
            errorMsg.value = '' 
          }, 10000)
        }
      }
    }

    const increment = () => {
      if(value.value < 100) {
        value.value += 1;
      } else {
        errorMsg.value = 'Maximum value reached';
      }
    }

    const decrement = () => {
      if(value.value > 0) {
        value.value -= 1;
      } else {
        errorMsg.value = 'Minimum value reached';
      }
    }

    return {
      selectMint,
      increment,
      decrement,
      value,
      errorMsg,
      price,
      remainingFreeCount,
      remainingHalfPricedCount,
      initialFreeCountWasNonZero,
      initialHalfPricedCountWasNonZero,
      selectedFreeCount,
      selectedHalfPricedCount,
      nav_back
    }
  }
}
</script>

  <style>
  .counter{
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  /* @media (max-width: 768px) {
  .counter {
    margin-top: 1rem;
  }
} */
  .controls{
    font-size: 2rem;
    background: none;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
    .controls:hover{
    background: #ccff0010;
    cursor: pointer;
    color: #cf0;
  }
  .price-symbol{
    color: #cf0
  }
  #demoInput{
    font-size: 4rem;
    text-align: center;
    padding: 0;
    border: none;
    margin-bottom: 1rem;
    background: none;
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.button-2.w-button:disabled {
    background-color: #606060 !important;
    color: #0f0f0f !important;
  }
</style>
