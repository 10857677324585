// stripeStore.js
import { defineStore } from 'pinia'
import { loadStripe } from '@stripe/stripe-js'
import { useUserStore } from "./user"
import stripeTestData from '@/assets/data/stripeTestData.json'
import stripeProductionData from '@/assets/data/stripeProductionData.json'

export const useStripeStore = defineStore({
  id: 'stripe',
  state: () => ({
    products: [],
    prices: [],
    customerMetadata: {},
    selectedPrice: null,
    selectedProduct: null,
    stripePromise: null,
    testMode: false,
  }),
  actions: {
    setProducts(products) {
      this.products = products
    },
    setPrices(prices) {
      this.prices = prices
    },
    setSelectedPrice(price) {
      this.selectedPrice = price
    },
    setCustomerMetadata(metadata) {
      this.customerMetadata = metadata
    },
    setSelectedProduct(product) {
      this.selectedProduct = product
    },
    async callCheckout(session_id, subscription_id) {
      const url = "https://gs6qnixyf3.execute-api.us-east-1.amazonaws.com/zerg/stripe/checkout";
      let payload;
      if (session_id){
        payload = {
          session_id: session_id || "",
          MODE: "PROD" 
        };
      } else if (subscription_id){
        payload = {
          subscription_id: subscription_id || "",
          MODE: "PROD" 
        };
      } else{
        throw new Error("No Subscription or Session ID detected.")
      }

      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
      });

      if (response.status !== 200) {
          throw new Error("Failed to call Zerg API");
      }

      const data = await response.json();
      return data;
    },
    async updateCustomerMetadata(user_id, session_id) {
      try {
        if (!user_id || !session_id) {
          throw new Error('Both user_id and session_id are required to update customer metadata.')
        }
    
        const response = await fetch('/api/stripe/update_metadata', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id, session_id })
        })
    
        const data = await response.json()
    
        if (!data.success) {
          throw new Error('Failed to update metadata on Stripe.')
        }
        
        return data;
        
      } catch (error) {
        console.error("Error updating customer metadata:", error.message)
        throw error
      }
    },
    async setPromise(){
      const stripePromise = loadStripe(process.env.VUE_APP_AWRD_STRIPE_PUBLIC_KEY_PROD);
      // const stripePromise = loadStripe(process.env.VUE_APP_AWRD_STRIPE_PUBLIC_KEY);
      // const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD);
      // const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      this.stripePromise = stripePromise
    },
    async fetchProducts() {
      // const response = await fetch('/api/stripe/products')
      // const products = await response.json()
      const products = this.testMode ? stripeTestData.products : stripeProductionData.products
      this.setProducts(products)
    },
    async fetchPrices() {
      // const response = await fetch('/api/stripe/prices')
      // const prices = await response.json()
      const prices = this.testMode ? stripeTestData.prices : stripeProductionData.prices
      this.setPrices(prices)
    },
    async purchaseProduct() {
      if (!this.selectedProduct || !this.selectedPrice) {
        throw new Error('No product or price selected')
      }
      
      const userStore = useUserStore()
      const userId = userStore.apiKey
      const username = userStore.username

      if(!userId || !username){
        throw new Error('User ID or username not found')
      }

      // Determine if the payment is recurring based on the selectedPrice's attributes
      const isRecurring = this.selectedPrice.recurring ? true : false

      const currentURL = window.location.origin + window.location.pathname
    
      // Create payload for server-side script
      const payload = {
        priceId: this.selectedPrice.id,
        price: this.selectedPrice.unit_amount / 100,
        productId: this.selectedPrice.product,
        isRecurring: isRecurring,
        success_url: `${currentURL}/success`,
        cancel_url: currentURL,
        user_id: userId,
        username: username
      }
    
      const response = await fetch('/api/stripe/checkout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      const session = await response.json()
    
      // Open Stripe Checkout
      const stripe = await this.stripePromise
      const result = await stripe.redirectToCheckout({ sessionId: session.id })
    
      if (result.error) {
        console.error(result.error.message)
      }
    }
    
  }
})
