import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'
import Index from '../views/Index.vue'
import GatedMint from '../views/GatedMint.vue'
import PublicMint from '../views/PublicMint.vue'
import PaymentSucceeded from '../views/PaymentSucceeded.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/success',
        name: 'PaymentSucceeded',
        component: PaymentSucceeded
    },
    {
        path: '/thank-you',
        name: 'GatedMint',
        component: GatedMint
    },
    {
        path: '/public',
        name: 'PublicMint',
        component: PublicMint
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to) => {
    if(to.query.warrior){
        Cookies.set('warrior', to.query.warrior)
    }
})

export default router