<template>
  <div>
    <section id="loading" class="app app-black wf-section">
      <div class="w-layout-blockcontainer main w-container"><img src="@/assets/images/loader2.gif" loading="lazy" width="125" height="125" alt="" class="loader cube">
        <a id="signIn" href="https://awrd.gg" target="_blank" class="signin checking w-inline-block">
          <div class="sign-in-button loading">Loading...</div>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useEligibilityStore } from '../store/eligibility'
import { useStripeStore } from '../store/stripe';

export default {
  name: 'LoadingView',
  setup(props, { emit }){
    const checking = ref(true);
    const eligibilityStore = useEligibilityStore()

      const loadStripeData = async () => {
        const stripeStore = useStripeStore()
        await stripeStore.setPromise()
        await stripeStore.fetchProducts()
        await stripeStore.fetchPrices()
        emit('next', 'ConfirmationView')
      }
      loadStripeData()

    return {
      checking,
      eligibilityStore,
    }
  }
}
</script>
