export const SeriesData = Object.freeze({
    series_one: {
        name: 'I',
        image: '/images/sIsmall.gif',
        redirect_url: 'https://opensea.io/collection/zergwarriors?search[stringTraits][0][name]=Series&search[stringTraits][0][values][0]=I',
        count: 500,
        released: true,
        key: 'series_one'
    },
    // series_two:{
    //     name: 'SERIES II',
    //     image: '/images/SII-P-half.png',
    //     count: 500,
    //     released: true,
    //     key: 's2'
    // }
})