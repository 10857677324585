import { defineStore } from "pinia";
import { ethers } from 'ethers';
import { addresses as mint_addresses, abi as mint_abi } from "@/assets/contracts/warriors.json";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useRevealStore } from "./reveal";

const INFURA_ID = process.env.VUE_APP_INFURA_PROJECT_ID

export const useContractStore = defineStore({
    id: 'contract',
    state: () => ({
        abi: mint_abi,  
        contract: null,
        chainId: 5, 
        seeds: {
            free: '0x5f30feede5deaaed58c5ce6038b57143',
            half: '0xcbf563b440f6aeb0049cc048aaeed8a0',
            al: '0x10af80d34235cc4ff8b5c4515d06b891',
            post_mint: '',
            skip_confirm: false
        },
        contractAddress: null,
        mintError: null,
        connectedAddress: null
    }),
    actions: {
        initialize: async function () {
            try {
                const providerOptions = {
                    walletconnect: {
                        package: WalletConnectProvider,
                        options: {
                            infuraId: INFURA_ID,
                        },
                    },
                };
                
                this.web3Modal = new Web3Modal({
                    network: this.chainId == 5 ? 'goerli' : "mainnet", 
                    cacheProvider: true, 
                    providerOptions, 
                    disableInjectedProvider: false,
                });
                
                this.provider = await this.web3Modal.connect();
        
                const provider = new ethers.providers.Web3Provider(this.provider);
                
                const network = await provider.getNetwork();
                this.chainId = network.chainId;
                
                const signer = provider.getSigner();
                this.connectedAddress = await signer.getAddress();
                
            } catch (error) {
                console.debug('Web3 initialization error:', error);
            }
        },
        checkChainId: async function(provider, ){
            const currentChainId = await provider.send('eth_chainId')
          if (parseInt(currentChainId, 16) !== this.chainId) {
              try {
                  // Attempt to switch to the correct chain
                  await provider.send('wallet_switchEthereumChain', [{ chainId: `0x${this.chainId.toString(16)}` }])
              } catch (switchError) {
                  // This error code indicates that the chain does not exist on the wallet
                  if (switchError.code === 4902) {
                      throw new Error('The requested chain does not exist on the wallet. Please add it manually.')
                  }
                  throw switchError
              }
          }
        },
        async connectWallet(){
            try{
                if(!this.provider){
                    await this.initialize()
                }
                return 'connected'
            } catch (e){
                console.error("Failed to connect wallet", e)
                return e
            }
        },
        async getTokensHeldByUser(){
            if(!this.contractAddress){
                this.contractAddress = mint_addresses[this.chainId]
            }
            if(!this.provider){
                await this.initialize()
            }
            const provider = new ethers.providers.Web3Provider(this.provider)
            const signer = provider.getSigner()
            const address = await signer.getAddress()
            await this.checkChainId(provider)
            const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer)
            const revealStore = useRevealStore()

            let tokenIds = []
            let balance = await contractWithSigner.balanceOf(address)
            for(let x = 0; x < balance; x++){
                let tokenId = await contractWithSigner.tokenOfOwnerByIndex(address, x)
                let tokenIdAsInteger = ethers.BigNumber.from(tokenId).toNumber()
                tokenIds.push(tokenIdAsInteger)
            }
            revealStore.setTokenIDList(tokenIds)
            return tokenIds
        },
        async getTotalSupply(){
            try {
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
                return await contractWithSigner.totalSupply()
            } catch (error) {
                console.debug("Error getting total supply:", error)
            }
        },
        async getOwnerOf(tokenId){
            try {
                if(!this.contractAddress){
                    this.contractAddress = mint_addresses[this.chainId]
                }
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, signer);
                return await contractWithSigner.ownerOf(tokenId)
            } catch (error) {
                console.debug("Error getting owner:", error)
            }
        },
        async getMintsByAWRDID(awrdID) {
            try {
                if (!this.contractAddress) {
                    this.contractAddress = mint_addresses[this.chainId];
                }
        
                const MAINNET_RPC_URL = 'https://mainnet.infura.io/v3/' + INFURA_ID // replace with your actual Infura Project ID
                const provider = new ethers.providers.JsonRpcProvider(MAINNET_RPC_URL);
        
                const contractWithSigner = new ethers.Contract(this.contractAddress, this.abi, provider);
        
                const mintsList1 = await contractWithSigner.mintListFreeMint(awrdID);
                const mintsList2 = await contractWithSigner.mintListHalfMint(awrdID);
        
                return {
                    list1: mintsList1.toNumber(),
                    list2: mintsList2.toNumber()
                };
            } catch (error) {
                console.debug('Error getting mint quantities:', error);
            }
        },
        async getSignature(token_ids){
            try{
                if (!this.provider){
                    await this.connectWallet()
                }
                const ethersProvider = new ethers.providers.Web3Provider(this.provider)
                const signer = ethersProvider.getSigner()
                const tokenIdsString = token_ids.join(", ")
                const message = `Revealing WARRIORS ${tokenIdsString}.\n\nWallet: ${this.connectedAddress}`
                
                const signature = await signer.signMessage(message)
                
                return {signature:signature, address: this.connectedAddress}
            } catch (error){
                console.debug('Error generating signature', error)
            }
        }
        
        
    }
});
