<template>
  <div id="app">
    <!-- <GTM></GTM> -->
    <router-view />
  </div>
</template>

<script>
// import GTM from './components/GTM'
import { onMounted } from 'vue';
export default {
  name: 'App',
  components: {
    // GTM
  },
  setup(){
    onMounted(()=> {
      window.document.title = 'Purchase WAR Points'
      window.document.description = 'WAR Points allow you to purchase avatars, upgrades, companions, and more within the ZERG ecosystem. Must be 18 or older to purchase.'
    })
  }
}
</script>

<style>
.html{
  background-color: #0f0f0f;
}
</style>
