<template>
  <section class="body-5">
    <section class="section-6">
      <!-- <div class="thin-band top div-block-14 div-block-15 subpage-top">
        <a href="https://zerg.studio" target="_blank" class="link-block top-link w-inline-block"><img src="images/ZERG.svg" loading="lazy" width="50" alt="" class="link home-hero-icon"></a>
      </div> -->
      <div class="w-layout-blockcontainer container-9 w-container">
        <img src="@/assets/images/buy_war.svg" loading="lazy" width="300" alt="" class="top-image">
        <div class="text-block-11">WAR Points allow you to purchase avatars, upgrades, companions, and more within the ZERG ecosystem.</div>
        <div data-current="Subscribe" data-easing="ease-in-out" data-duration-in="300" data-duration-out="50" class="tabs w-tabs">
          <div class="tabs-menu-2 w-tab-menu">
            <a data-w-tab="One time" class="tab-link-tab-1-2 w-inline-block w-tab-link" :class="{'w--current': !showSubscriptions}" @click="toggleOneTime()">
              <div>One-time</div>
            </a>
            <a data-w-tab="Subscribe" class="tab-link-tab-2-2 w-inline-block w-tab-link" :class="{'w--current': showSubscriptions}" @click="toggleSubscription()">
              <div>Subscribe</div>
            </a>
          </div>
          <div class="w-tab-content">
            <div data-w-tab="One time" class="w-tab-pane" :class="{'w--tab-active': !showSubscriptions}" v-if="!showSubscriptions">
              <ul role="list" class="list-3 w-list-unstyled">
                <li @click.prevent="priceClicked(price)" class="list-item" v-for="(price, index) in stripePrices" :key="index" :class="{'selected': price.selected, 'first': index === 0, 'last': index === stripePrices.length - 1}">
                  <div class="div-block-21 div-block-22 div-block-23 div-block-24 div-block-25">
                    <img width="50" loading="lazy" :src="getPriceImage(price)" alt="">
                    <div class="text-block-9">{{ price.metadata.points }}</div>
                  </div>
                  <div class="price-and-savings">
                    <div class="text-block-10">${{ price.unit_amount / 100 }}</div>
                    <div class="text-block-13">{{ price.savings }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div data-w-tab="Subscribe" class="w-tab-pane" :class="{'w--tab-active': showSubscriptions}" v-if="showSubscriptions">
              <ul role="list" class="list-3 w-list-unstyled">
                <li @click.prevent="priceClicked(price)" class="list-item" v-for="(price, index) in stripePrices" :key="index" :class="{'selected': price.selected, 'first': index === 0, 'last': index === stripePrices.length - 1}">
                  <div class="div-block-21 div-block-22 div-block-23 div-block-24 div-block-25">
                    <img width="50" loading="lazy" :src="getPriceImage(price)" alt="">
                    <div class="point-total-container">
                      <div class="text-block-9">{{ price.metadata.points }}</div>
                      <div class="text-block-9 left-subtext">{{ price.bonus }} Bonus Points</div>
                    </div>
                  </div>
                  <div class="price-and-savings">
                    <div class="text-block-10">${{ price.unit_amount / 100 }}</div>
                    <div class="text-block-13">Per Month</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="text-block-14">Subscribe for up to 1250 Bonus Points each month!</div>
        <!-- Integrated the confirmClicked method to the "Purchase Points" button -->
        <a v-if="!is_maintenance" href="#" @click.stop="confirmClicked" class="purchase w-button" :class="{'inactive': !stripeStore.selectedProduct || isRevealing}" :disabled="!stripeStore.selectedProduct || isRevealing">{{ !stripeStore.selectedProduct ? 'Select Package' : "Complete Purchase" }}</a>
        <a v-else class="purchase w-button maintenance" :class="{'inactive': true}" :disabled="true">{{ !stripeStore.selectedProduct ? 'In Maintenance' : "In Maintenance" }}</a>
      </div>
    </section>
    <div class="text-block-12">When purchasing Points from ZERG STUDIO, you receive a limited, non-refundable license to use them, with no real-world value. By choosing the subscription option, you affirm you're 18 or older and authorize monthly charges until cancellation. You agree to ZERG STUDIO's <a href="https://zerg.studio/legal" class="link-7">Terms of Service and Privacy Policy</a> and can cancel anytime via the billing settings within Stripe, with charges applying for the current period.</div>
</section>

</template>

  
  <script>
  import { useRevealStore } from '../store/reveal'
  import { useStripeStore } from '../store/stripe'
  import { useUserStore } from '../store/user'
  // import { computed, watch, ref } from 'vue';
  import { computed, ref } from 'vue';
  
  export default {
    name: 'ConfirmationView',
    setup(props, {emit}){
      const revealStore = useRevealStore()
      const stripeStore = useStripeStore()
      const isRevealing = ref(false)
      const is_maintenance = ref(true)
      const errorText = ref('')
      const priceClicked = (priceDetail) => {
        stripePrices.value.forEach(price => {
          price.selected = false
        })
        priceDetail.selected = true
        stripeStore.setSelectedProduct(priceDetail.product)
        stripeStore.setSelectedPrice(priceDetail)
      }
      const showSubscriptions = ref(true)

      const resetSelectedPrice = () => {
        stripePrices.value.forEach(price => {
          price.selected = false
        });
        stripeStore.setSelectedProduct(null)
        stripeStore.setSelectedPrice(null)
      };

      const toggleOneTime = () => {
        showSubscriptions.value = false
        resetSelectedPrice()
      };

      const toggleSubscription = () => {
        showSubscriptions.value = true
        resetSelectedPrice()
      };
      
      const userStore = useUserStore()
      const getPriceImage = (price) => {
        return price.image
      }

      const loginWithAwardable = () => {
            const currentURL = window.location.origin
            const popupWindow = window.open(
                `https://awrd.gg/login?redirect_uri=${encodeURIComponent(
                currentURL
                )}&scope=APP_KEY%20API_USERNAME`,
                '_blank',
                'width=500,height=500'
            )
            checkURL(popupWindow)
        }

        const checkURL = (popupWindow) => {
            const checkPopup = setInterval(() => {
                let url;
                if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                    url = window.location.href; // Check the current window's URL if the popup doesn't exist
                } else {
                    try {
                        url = popupWindow.location.href;
                    } catch (error) {
                        console.log('Unable to access popup window, probably due to cross-origin issues')
                    }
                }
                if (url) {
                    const searchParams = new URLSearchParams(new URL(url).search)
                    let APP_KEY = searchParams.get('APP_KEY')
                    let API_USERNAME = searchParams.get('API_USERNAME')

                    if (APP_KEY) {
                        if (API_USERNAME){
                          userStore.setUsername(API_USERNAME)
                        }
                        const parts = APP_KEY.split(':-:')
                        if (parts.length === 2) {
                            const API_TOKEN = parts[0]
                            APP_KEY = parts[1]

                            clearInterval(checkPopup)
                            userStore.setUserData(APP_KEY, API_TOKEN)
                            userStore.setIsAWRD(true)
                            if (popupWindow && !popupWindow.closed) {
                                popupWindow.close()
                                purchasePoints()
                            }
                        }
                    }
                }
            }, 1000)
        }
      const purchasePoints = async () => {
        if(stripeStore.selectedProduct){
          isRevealing.value = true
          await stripeStore.purchaseProduct()
          isRevealing.value = false
        } else{
          errorText.value = "Please select a price before proceeding.";
          setTimeout(() => {
            errorText.value = '';
          }, 10000);
        }
        emit('next', 'LoadingView')
      }
      const confirmClicked = async () => {
        if (!userStore.apiKey){
          loginWithAwardable()
          return
        }
        await purchasePoints()
        // const reveal_success = await revealStore.revealTokens()
        // isRevealing.value = false
        // if(reveal_success){
        //   emit('next', 'YourTokens')
        // } else {
        //   errorText.value = "Your error message here."
        //   setTimeout(() => {
        //     errorText.value = ''
        //   }, 10000);
        // }
      }
      const get_parsed_id = (id) => {
        return Number(id) + 1
      }
      const stripePrices = computed(() => {
        if (showSubscriptions.value) {
          return stripeStore.prices.filter(price => price.recurring)
        } else {
          return stripeStore.prices.filter(price => !price.recurring)
        }
      })

      const toggleSubscriptionView = () => {
        showSubscriptions.value = !showSubscriptions.value
      }
  
      return {
        confirmClicked,
        tokens: revealStore.getTotalSealedTokens,
        stripePrices,
        stripeStore,
        priceClicked,
        isRevealing,
        get_parsed_id,
        errorText,
        showSubscriptions,
        toggleSubscriptionView,
        userStore,
        getPriceImage,
        loginWithAwardable,
        toggleOneTime,
        toggleSubscription,
        is_maintenance
      }
    }
  }
  </script>
  <style scoped>
  .maintenance{
    cursor:default;
  }
.price-toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.price-toggle-buttons button.active {
  background-color: #333; /* or any other color to highlight the active button */
  color: #fff;
}

  .main.main-list.mint-confirmation{
    height: 100vh;
  }
  .link-block-2{
    gap: .5rem;
    padding-right: 1rem;
  }
  .button-2.w-button:disabled {
    background-color: #606060 !important;
    color: #0f0f0f !important;
  }
  .talisman {
    border: 1px solid transparent;
  }
  .selected-border{
    border-color: #cf0;
  }
  .talisman-check{
    width: 24px;
    height: 24px;
  }
  .error-text{
    color:red;
  }
  .w--current{
    background-color: #00000000;
  }
  </style>