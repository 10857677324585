<template>
    <a v-if="type == 'top'" id="skipToMint" @click="loginWithAwardable" class="signin w-inline-block">
        <div class="pointer sign-in-button">PURCHASE POINTS</div>
    </a>
    <a v-else id="signIn" @click="loginWithWallet" class="pointer link-2 blackk">
        PROCEED WITH WALLET
    </a>
</template>

<script>
import { useUserStore } from '../store/user'
import { onMounted } from 'vue'
import { useContractStore } from '../store/contract'

export default {
    name: 'LoginWith',
    props: {
        type: String,
    },
    emits: ['login'],
    setup(props, { emit }) {
        const userStore = useUserStore()
        const skipToMint = () => {
            emit('login')
        }
        const loginWithAwardable = () => {
            emit('login')
            return
            // const currentURL = window.location.href
            // const popupWindow = window.open(
            //     `https://awrd.gg/login?redirect_uri=${encodeURIComponent(
            //     currentURL
            //     )}&scope=APP_KEY`,
            //     '_blank',
            //     'width=500,height=500'
            // )
            // checkURL(popupWindow);
        }

        const checkURL = (popupWindow) => {
            const checkPopup = setInterval(() => {
                let url;
                if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
                    url = window.location.href; // Check the current window's URL if the popup doesn't exist
                } else {
                    try {
                        url = popupWindow.location.href;
                    } catch (error) {
                        console.log('Unable to access popup window, probably due to cross-origin issues')
                    }
                }
                if (url) {
                    const searchParams = new URLSearchParams(new URL(url).search)
                    let APP_KEY = searchParams.get('APP_KEY')
                    
                    if (APP_KEY) {
                        const parts = APP_KEY.split(':-:')
                        if (parts.length === 2) {
                            const API_TOKEN = parts[0]
                            APP_KEY = parts[1]

                            clearInterval(checkPopup)
                            userStore.setUserData(APP_KEY, API_TOKEN)
                            userStore.setIsAWRD(true)
                            emit('login')
                            emit('appKeyFound') 
                            if (popupWindow && !popupWindow.closed) {
                                popupWindow.close()
                            }
                        }
                    }
                }
            }, 1000)
        }
        const loginWithWallet = async () =>{
            const contractStore = useContractStore()
            const userStore = useUserStore()
            const walletConnected = await contractStore.connectWallet()
            if(walletConnected == 'connected'){
                userStore.setIsAWRD(false)
                emit('login')
            } else {
                console.debug('Issue connecting wallet:', walletConnected)
            }
        }

        onMounted(() => {
            checkURL(null);
        })
        
        return {
            loginWithAwardable,
            skipToMint,
            loginWithWallet,
        }
    },
}

</script>

<style scoped>
.pointer{
    cursor:pointer;
}
</style>
